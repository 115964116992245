import { Html } from "@react-three/drei";
import { useFrame } from "@react-three/fiber";
import { useControls } from "leva";
import { useRef } from "react";

export default function Markers({data, debug = false}) {
    const ref = useRef()

    // useFrame(() => {
    //     console.log(ref.current)
    // })

    const markers = data.map((data, index) => {
        const position = [
            data.position.x, // x
            data.position.z, // y
            data.position.y // z
        ]
        return (
            <mesh position={position} key={data.name} ref={index === 1 ? ref : null}>
                <sphereGeometry args={[10, 16, 8]}/>
                <meshBasicMaterial visible={debug} color={'red'} wireframe/>
                <Html
                    wrapperClass="marker"
                    center
                >
                    <div className="marker__point">
                        <div className="marker__label">{data.name}</div>
                    </div>
                </Html>
            </mesh>
        )
    })

    return (
        <group>
            {markers}
        </group>
    )
}