import {Environment, FlyControls, PointerLockControls, useCubeTexture } from "@react-three/drei";
import { useFrame, useLoader, useThree, extend } from "@react-three/fiber";
import { TextureLoader, MeshBasicMaterial, BackSide, CubeTextureLoader, CubeReflectionMapping, sRGBEncoding, LinearEncoding, Vector3 } from "three";
import { useControls } from "leva";
import { Suspense, useEffect, useRef } from "react";
import Markers from "./Markers";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import PanCam from "./PanCam";

function Scene () {
    const state = useThree();

    // Data
    const camPositions = [
        { x: 999.668807,  y: -523.844637, z: 120.0},
        { x: 918.00849, y: -733.752823, z: 120.0},
    ]
    const markersData = [
        {
            name: 'Sink',
            position: {x: 1007.892382, y: -394.244707, z : 104.276807},
            color: 'red'
        },
        {
            name: 'Table',
            position: {x: 956.376381, y: -847.669343, z: 85.155724},
            color: 'green'
        },
        {
            name: 'Worktop',
            position: {x: 1028.237536, y: -665.462243, z: 104.276808},
            color: 'blue'
        },
    ]

    // Textures
    const envMaps = useLoader(
        CubeTextureLoader, 
        [[
            'cube_maps/capture_1/View4.png', 
            'cube_maps/capture_1/View1.png', 
            'cube_maps/capture_1/View5.png', 
            'cube_maps/capture_1/View6RotNeg90.png', 
            'cube_maps/capture_1/View3.png', 
            'cube_maps/capture_1/View2.png'
        ],
        [
            'cube_maps/capture_2/View4.png', 
            'cube_maps/capture_2/View1.png', 
            'cube_maps/capture_2/View5.png', 
            'cube_maps/capture_2/View6RotNeg90.png', 
            'cube_maps/capture_2/View3.png', 
            'cube_maps/capture_2/View2.png'
        ]],
    );
    const setEncodingSRGB = (sRGB) => {
        envMaps.forEach(texture => {
            texture.mapping =  CubeReflectionMapping
            texture.encoding = sRGB ? sRGBEncoding : LinearEncoding
        });
    }

    // Controls
    const switchView = (view) => {
        setCameraPos({cameraPos : {x: camPositions[view].x, y: camPositions[view].z, z: camPositions[view].y}})

        if(state.scene) {
            state.scene.background = envMaps[view]    
        }
    }
    const {view, markersDebug} = useControls({
        view: {
            options: [0, 1],
            onChange: (view) => switchView(view),
            transient: false
        },
        sRGB: {
            value: true,
            onChange: (sRGB) => setEncodingSRGB(sRGB),
        },
        markersDebug: false
    })
    const [{cameraPos}, setCameraPos] = useControls(() => ({
        cameraPos: {
            value: { x: camPositions[view].x, y: camPositions[view].z, z: camPositions[view].y },
        }
    }))
    
    useFrame(() => {
        state.camera.position.x = cameraPos.x
        state.camera.position.y = cameraPos.y
        state.camera.position.z = cameraPos.z
    })

    return (
        <>
            <Markers data={markersData} debug={markersDebug}/>
            <PanCam />
        </>
    )
}

export default Scene;