import { useFrame, useThree, extend } from "@react-three/fiber";
import { Vector3 } from "three";
import { useEffect, useRef } from "react";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";

// Extend will make OrbitControls available as a JSX element called orbitControls for us to use.
extend({ OrbitControls });

// https://discourse.threejs.org/t/how-can-i-rotate-my-camera-by-dragging/30300
// https://codepen.io/mjurczyk/pen/gOwbMVr
const PanCam = () => {
    const {
      camera,
      gl: { domElement },
    } = useThree();
  
    // Ref to the controls, so that we can update them on every frame using useFrame
    const controls = useRef();

    const updateCameraOrbit = () => {
        // I have no idea why this works!? But it stops the HTML comp from drei jumping around
        const forward = new Vector3();
        camera.getWorldDirection(forward);
        controls.current.target.copy(camera.position).add(forward);
    };

    useEffect(() => {
        controls.current.addEventListener('end', updateCameraOrbit);
        updateCameraOrbit();

        return () => controls.current.removeEventListener('end', updateCameraOrbit)
    }, [])
  
    useFrame((state) => controls.current.update());

    return <orbitControls 
        ref={controls} 
        args={[camera, domElement]} 
        enableRotate={false}
        enableZoom={false}
    />;
};

export default PanCam;