import React, { Suspense } from 'react';
import { Canvas } from "@react-three/fiber"
import Scene from './Scene';

function App() {
  return (
    <Suspense>
      <Canvas camera={{fov: 100, far: 2000, position: [0,0,0]}}>
        <Scene view={1}/>
      </Canvas>
    </Suspense>
  );
}

export default App;
